import React, { useEffect, useMemo, useState } from "react";
import styles from "./footer.module.scss";
import { Link, Stack } from "@fluentui/react";
import ProjectInvitationService from "../../api-services/ProjectInvitationService";

const Footer: React.FC = () => {
  const service = useMemo(() => new ProjectInvitationService(), []);
  const [legalUrl, setLegalUrl] = useState<string>("");
  useEffect(() => {
    service.GetLegalUrl().then((data) => {
      setLegalUrl(data);
    });
  }, []);

  return (
    <Stack horizontal horizontalAlign="start">
      <Link
        target="_Blank"
        title="Legal"
        href={legalUrl}
        className={styles.link}
      >
        Legal
      </Link>
      <Link title="Privacy Notice" href="/privacy" className={styles.link}>
        Privacy Notice
      </Link>
    </Stack>
  );
};
export default Footer;
