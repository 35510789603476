import React from "react";
import styles from "./header.module.scss";
import logo from "../../../../logo.png";
const Header: React.FC = () => {
  
  return (
    <div className={styles.header}>
      <header className={styles.siteHeader}>
        <img src={logo} className={styles.logo} alt="logo" />
        <div className={styles.title}>Document Access</div>
      </header>
    </div>
  );
};
export default Header;
