import { Routes, Route } from "react-router-dom";

import Home from "../pages/home/home";
import Tac from "../pages/tac/tac";
import DocGen from "../pages/docgen/docgen";
import NotFound from "../pages/not-found/NotFound";
import Privacy from "../pages/privacy/privacy";

const AppRouting: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<NotFound />}></Route>
        <Route path="/home" element={<NotFound />}></Route>
        <Route path="/tac/:projectId/:invitationId" element={<Tac />}></Route>
        <Route
          path="/docgen/:projectId/:invitationId"
          element={<DocGen />}
        ></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};
export default AppRouting;
