import {
  PrimaryButton,
  Label,
  Stack,
  mergeStyles,
  mergeStyleSets,
  Shimmer,
  ShimmerElementType,
  ShimmerElementsGroup,
  DocumentCard,
  DocumentCardActivity,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardImage,
  IDocumentCardStyles,
  IDocumentCardActivityPerson,
  IIconProps,
  ImageFit,
  registerIcons,
  ActivityItem,
  IActivityItemProps,
} from "@fluentui/react";
import { PDFIcon, PaddingLeftIcon } from "@fluentui/react-icons-mdl2";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import styles from "./docgen.module.scss";

import ReportService from "../../common/api-services/ReportService";

import { IReportDocument } from "../../common/models/IReportDocument";

const DocGen = () => {
  registerIcons({
    icons: {
      PDFIcon: <PDFIcon />,
    },
  });
  let navigate = useNavigate();
  let location = useLocation();
  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
    },
    pane: {
      maxWidth: 400,
      position: "relative",
    },
  });
  const cardStyles: IDocumentCardStyles = {
    root: {
      display: "inline-block",
      marginRight: 20,
      marginBottom: 20,
      width: 320,
    },
  };
  const oneNoteIconProps: IIconProps = {
    iconName: "PDFIcon",
    styles: {
      root: {
        color: "#f40f02",
        fontSize: "120px",
        width: "120px",
        height: "120px",
      },
    },
  };
  const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });
  const wrapperStyles = { display: "flex" };

  const getCustomElements = (): JSX.Element => {
    return (
      <div style={wrapperStyles}>
        <ShimmerElementsGroup
          flexWrap
          width="100%"
          shimmerElements={[
            {
              type: ShimmerElementType.line,
              width: 400,
              height: 460,
              verticalAlign: "top",
            },
            {
              type: ShimmerElementType.line,
              width: 400,
              height: 16,
              verticalAlign: "bottom",
            },
          ]}
        />
      </div>
    );
  };
  const params = useParams<{
    projectId: string;
    invitationId: string;
  }>();

  const service = useMemo(() => new ReportService(), []);
  const [reportData, setReportData] = useState<any>();
  const [reportFilename, setReportFilename] = useState<any>();
  const [isReportGenerated, { setTrue: setDataLoaded }] = useBoolean(false);
  const [reportDocumentManagement, setReportDocumentManagement] = useState<
    IReportDocument[]
  >([]);
  const onDownloadReport = (
    event: any,
    from: string,
    blobUrl: any,
    fileName?: string
  ) => {
    let alink = document.createElement("a");
    if (from === "auto") {
      alink.href = blobUrl;
      alink.download = fileName ?? "CustomerReport.pdf";
    } else {
      alink.href = reportData;
      alink.download = reportFilename ?? "CustomerReport.pdf";
    }
    alink.style.display = "none";
    document.body.append(alink);
    // Programmatically click the element.
    //alink.click();
    alink.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      alink.remove();
    }, 2000);
  };

  const getGeneratedReport = (
    projectId: string,
    invitationId: string,
    fileId: string
  ) => {
    service.GetCustomerReport(projectId, invitationId, fileId).then((data) => {
      if (data.reportData == null) {
        navigate(`/notfound`);
      }

      fetch(`data:application/pdf;base64,${data.reportData}`)
        .then(function (base64resp) {
          if (base64resp) {
            return base64resp.blob();
          }
        })
        .then(function (reportBlob) {
          if (reportBlob) {
            const blobUrl = URL.createObjectURL(reportBlob);
            setReportData(blobUrl);
            setReportFilename(data.reportFilename);
            setDataLoaded();
            onDownloadReport(null, "auto", blobUrl, data.reportFilename);
          }
        });
      //console.log(data);
    });
  };

  const getDocuments = useCallback(() => {
    if (params.projectId && params.invitationId) {
      service
        .GetDocuments(params.projectId, params.invitationId)
        .then((data: any) => {
          if (data.length > 0) {
            setReportDocumentManagement(data);
          }
        });
    }
  }, [params.projectId, params.invitationId, service]);

  useEffect(() => {
    getDocuments();
  }, []);
  const _onFormatDate = (date: string): string => {
    const myDate = new Date(date);
    return (
      ("0" + myDate.getDate()).slice(-2) +
      "." +
      ("0" + (myDate.getMonth() + 1)).slice(-2) +
      "." +
      myDate.getFullYear() +
      ", " +
      ("0" + myDate.getHours()).slice(-2) +
      ":" +
      ("0" + myDate.getMinutes()).slice(-2)
    );
  };
  const documents = reportDocumentManagement.map(function (x: IReportDocument) {
    return (
      <DocumentCard
        aria-label={
          "Document Card with icon. How to make a good design. " +
          "Last modified by Christian Bergqvist in January 1, 2019."
        }
        key={x.id}
        styles={cardStyles}
        onClick={() => {
          if (params.invitationId && params.projectId)
            getGeneratedReport(params.projectId, params.invitationId, x.id);
        }}
      >
        <DocumentCardImage
          height={150}
          imageFit={ImageFit.cover}
          iconProps={oneNoteIconProps}
        />
        <DocumentCardDetails>
          <DocumentCardTitle
            title={x.fileName ? x.fileName : ""}
            shouldTruncate
          />
        </DocumentCardDetails>
        <ActivityItem
          activityDescription={
            <span style={{ paddingLeft: 10 }}>
              Uploaded on {_onFormatDate(x.uploadDate.toString())}
            </span>
          }
          key={x.id}
        ></ActivityItem>
      </DocumentCard>
    );
  });

  return (
    <>
      {reportDocumentManagement.length > 0 && (
        <Stack>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal horizontalAlign="start">
              <Label className={styles.PageHeader}>Download Report</Label>
            </Stack>
          </Stack>
          <Stack>
            <hr className={styles.horizontalLine}></hr>
          </Stack>
          <Stack horizontal horizontalAlign="start">
            {documents}
          </Stack>
        </Stack>
      )}
    </>
  );
};
export default DocGen;
