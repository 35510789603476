import {
  PrimaryButton,
  Label,
  Stack,
  StackItem,
  IIconProps,
  registerIcons,
  TextField,
  ITextFieldStyles,
  IButtonStyles,
  IconButton,
  SelectionMode,
  IDropdown,
  mergeStyleSets,
  ScrollablePane,
  IScrollablePaneStyles,
  IStackStyles,
  Shimmer,
  ShimmerElementType,
  IShimmerElement,
  mergeStyles,
  MessageBar,
  MessageBarType,
  Link,
} from "@fluentui/react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { QandAIcon } from "@fluentui/react-icons-mdl2";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";

import ProjectInvitationService from "../../common/api-services/ProjectInvitationService";

//import projects from "../../../local-json/Projects.json"
import styles from "./tac.module.scss";
import { relative } from "path";
import {
  IProjectInvitation,
  TacResponseStatus,
} from "../../common/models/IProjectInvitation";
import { IFaqDocument } from "../../common/models/IFaqDocument";

//import { InvalidatedProjectKind } from "typescript";

const TermsAndConditions = () => {
  let navigate = useNavigate();
  const gotoFaq = () => {
    let path = `/home`;
    navigate(path);
  };

  registerIcons({
    icons: {
      QandAIcon: <QandAIcon />,
    },
  });

  const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
    },
    pane: {
      maxWidth: 400,
      position: "relative",
    },
  });

  const shimmerWithElementRow = [
    { type: ShimmerElementType.circle },
    { type: ShimmerElementType.gap, width: "2%" },
    { type: ShimmerElementType.line },
  ];
  const textfieldStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 350,
      paddingRight: 50,
      paddingBottom: 10,
    },
  };
  const stackStyles50: IStackStyles = {
    root: {
      width: `50%`,
    },
  };
  const tacContentStyles: IStackStyles = {
    root: {
      backgroundColor: `#eeeeee`,
      //outline: `1px solid red`,
      width: `100%`,
      height: `100%`,
      overflowY: `auto`,
      padding: `0px 15px`,
      maxHeight: `calc(100vh - 300px)`,
    },
  };

  const [messageTac, setMessageTac] = useState<string>("");
  const [messageBarTypeTac, setMessageBarTypeTac] = useState<MessageBarType>();

  const [projectInvitation, setProjectInvitation] = useState<any>();

  const onChangeTxtField = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    newValue?: string
  ): void => {
    let obj: any = projectInvitation ? projectInvitation : {};
    if (field === "recipientEmailName") {
      obj["recipientFullEmail"] = newValue + obj["recipientEmailDomain"];
    }

    obj[field] = newValue;
    setProjectInvitation(obj);
  };

  const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
    root: contentStyles.pane,
  };

  //const { projectId = "", invitationId = ""} = useParams();
  const params = useParams<{ projectId: string; invitationId: string }>();

  const service = useMemo(() => new ProjectInvitationService(), []);

  //const projectTitle = "None";
  //const mailDomain ="@jeschor.de";
  //const projectTac = "";
  /*
  const currentProject = projects.filter(project => project.projectId.toLowerCase() == projectId.toLowerCase());
  const currentInvitation = currentProject[0].receipients.filter(invitation => invitation.receipientId == parseInt(invitationId));
  if (currentProject == null || currentProject.length != 1 || currentInvitation == null || currentInvitation.length != 1)
  {
    return (<Navigate to="/NotFound" />);
  }
  
  const projectTitle = currentProject[0].projectName;
*/
  const projectFaqIcon: IIconProps = {
    iconName: "QandAIcon",
    styles: {
      root: [
        {
          selectors: {
            ">span": {
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };
  const getProjectInvitationDetails = useCallback(() => {
    if (params.projectId && params.invitationId) {
      service
        .GetInvitationDetails(params.projectId, params.invitationId)
        .then((data) => {
          setProjectInvitation(data);
          console.log(data);
        });
    } else {
      navigate(`/notfound`);
    }
  }, [params.projectId, params.invitationId, service]);

  const resetMessageTac = () => {
    setMessageTac("");
    setMessageBarTypeTac(undefined);
  };

  const [
    isTacSubmitted,
    { setTrue: setTacSubmitted, setFalse: resetTacSubmitted },
  ] = useBoolean(false);

  const [faqData, setFaqData] = useState<any>();
  const [faqFilename, setFaqFilename] = useState<any>();

  const onDownloadFaq = (
    event: any,
    from: string,
    blobUrl: any,
    fileName?: string
  ) => {
    service.GetFaq().then((data) => {
      if (
        data.faqDocumentFetchSuccess == null ||
        data.faqDocumentFetchSuccess == false
      ) {
        navigate(`/notfound`);
      } else {
        fetch(`data:application/pdf;base64,${data.faqDocumentData}`)
          .then(function (base64resp) {
            if (base64resp) {
              return base64resp.blob();
            }
          })
          .then(function (faqBlob) {
            if (faqBlob) {
              //console.log("Filename:" + data.reportFilename)
              const faqBlobUrl = URL.createObjectURL(faqBlob);
              let alink = document.createElement("a");
              alink.href = faqBlobUrl;
              alink.download = data.faqDocumentFilename ?? "FAQ.pdf";
              alink.style.display = "none";
              document.body.append(alink);
              alink.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
            }
          });
      }
    });
  };

  const onUpdateInvitation = () => {
    let obj = projectInvitation as IProjectInvitation;
    setTacSubmitted();

    if (
      projectInvitation.recipientEmailName &&
      projectInvitation.recipientFamilyName &&
      projectInvitation.recipientName &&
      projectInvitation.recipientCompanyName
    ) {
      setMessageTac(`You are about to accept RB data access terms`);
      setMessageBarTypeTac(MessageBarType.info);

      obj.tacAccepted = true;
      obj.recipientFullEmail =
        projectInvitation.recipientEmailName +
        projectInvitation.recipientEmailDomain;
      service.UpdateInvitation(obj as IProjectInvitation).then((data: any) => {
        if (data?.error || data == TacResponseStatus.Error) {
          console.log("Update Error");
          setMessageTac(`An error occured during update`);
          setMessageBarTypeTac(MessageBarType.error);
        } else {
          console.log("Update Success");
          if (data == TacResponseStatus.Success) {
            setMessageTac(
              `You have successfully accepted the terms and conditions`
            );
            setMessageBarTypeTac(MessageBarType.success);
          } else if (data == TacResponseStatus.Exists) {
            setMessageTac(`You have already accepted the terms and conditions`);
            setMessageBarTypeTac(MessageBarType.info);
          } else if (data == TacResponseStatus.NotFound) {
            navigate(`/notfound`);
          }
        }
      });
    } else {
      resetTacSubmitted();
      console.log("Field is required");
      setMessageTac(`Required field value missing`);
      setMessageBarTypeTac(MessageBarType.error);
    }
  };
  useEffect(() => {
    getProjectInvitationDetails();
  }, [getProjectInvitationDetails]);

  return (
    <Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal horizontalAlign="start">
          <Label className={styles.PageHeader}>
            Roland Berger Materials Access - {projectInvitation?.projectName}
          </Label>
        </Stack>
        <Stack horizontal horizontalAlign="end">
          <PrimaryButton
            text="FAQ"
            className={styles.buttonNewProject}
            iconProps={projectFaqIcon}
            onClick={(event) => onDownloadFaq(event, `click`, ``, ``)}
          />
        </Stack>
      </Stack>
      <Stack>
        <hr className={styles.horizontalLine}></hr>
      </Stack>
      {messageTac != "" && (
        <Stack>
          <MessageBar
            messageBarType={messageBarTypeTac}
            isMultiline={false}
            dismissButtonAriaLabel="Close"
            onDismiss={resetMessageTac}
          >
            {messageTac}
          </MessageBar>
        </Stack>
      )}
      <Stack horizontal horizontalAlign="space-between">
        <Label className={styles.contentHeader}>
          You are invited to the Project {projectInvitation?.projectName}.
          Please enter the following information and accept the terms and
          conditions.
        </Label>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Label className={styles.contentHeader}>
          Please feel free to share the link with someone from your
          organization.
        </Label>
      </Stack>
      <Stack horizontal>
        <Stack.Item grow={1}>
          <Stack horizontalAlign="start">
            <Stack>
              <TextField
                label="Name"
                id="tacName"
                required
                styles={textfieldStyles}
                onChange={(ev, val) => {
                  onChangeTxtField(ev, "recipientName", val);
                }}
              />
            </Stack>
            <Stack>
              <TextField
                label="Last Name"
                id="tacLastName"
                required
                styles={textfieldStyles}
                onChange={(ev, val) => {
                  onChangeTxtField(ev, "recipientFamilyName", val);
                }}
              />
            </Stack>
            <Stack>
              <TextField
                label="Company"
                id="tacCompany"
                required
                styles={textfieldStyles}
                onChange={(ev, val) => {
                  onChangeTxtField(ev, "recipientCompanyName", val);
                }}
              />
            </Stack>
            <Stack horizontal styles={textfieldStyles}>
              <Stack verticalAlign="center" styles={stackStyles50}>
                <TextField
                  label="Email Address"
                  id="tacMailAdress"
                  required
                  //styles={textfieldStyles}
                  onChange={(ev, val) => {
                    onChangeTxtField(ev, "recipientEmailName", val);
                  }}
                />
              </Stack>
              <Stack verticalAlign="end" styles={stackStyles50}>
                <div style={{ padding: "0px 0px 8px 8px" }}>
                  {projectInvitation?.recipientEmailDomain}
                </div>
              </Stack>
            </Stack>
            <Stack horizontal horizontalAlign="center">
              <Label>
                Information on the handling of your personal data is provided in
                our{" "}
                <Link
                  title="Privacy Notice"
                  href="/privacy"
                  className={styles.link}
                >
                  Privacy Notice
                </Link>
              </Label>
            </Stack>
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                text="Accept RB data access terms"
                className={styles.buttonSave}
                disabled={isTacSubmitted}
                onClick={onUpdateInvitation}
              />
            </Stack>
          </Stack>
        </Stack.Item>
        <Stack.Item grow={2}>
          <Stack styles={tacContentStyles}>
            <div
              id="tacContent"
              className="tacContent"
              style={{ padding: "0px 15px" }}
              dangerouslySetInnerHTML={{
                __html: projectInvitation?.projectCompanyTacContent,
              }}
            ></div>
          </Stack>
        </Stack.Item>
      </Stack>
      <Stack>
        <hr className={styles.horizontalLine}></hr>
      </Stack>
    </Stack>
  );
};

export default TermsAndConditions;
