import React from "react";
import AppRouting from "../../../routing/AppRouting";
import Header from "../header/header";
import Footer from "../footer/footer";

const AppLayout: React.FC = () => {
  return (
    <div>
      <Header />
      <div className="AppContent">
        <AppRouting />
      </div>
      <div className="AppContent">
        <Footer />
      </div>
    </div>
  );
};

export default AppLayout;
