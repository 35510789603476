export enum TacResponseStatus
{
    NotFound = 0,
    Success = 1,
    Exists = 2,
    Error = 3
}

export interface IProjectInvitation {
  projectId?: string;
  invitationId?: string;
  projectName?: string;
  projectCompanyCode?: string;
  projectCompanyName?: string;
  projectCompanyTacContent?: string;
  projectCompanyTacVersion?: string;
  recipientName?: string;
  recipientFamilyName?: string;
  recipientFullEmail?: string;
  recipientEmailName?: string;
  recipientEmailDomain?: string;
  recipientCompanyName?: string;
  tacAccepted?: boolean;
  tacAcceptedAt?: Date;
  invitationStatus?: string;
}