import { IReportGeneration } from "../models/IReportGeneration";
import axiosHelper from "./AxiosApi";
export default class ReportService {
  public GetCustomerReport(
    projectId: string,
    invitationId: string,
    fileId: string
  ): Promise<IReportGeneration> {
    //return axiosHelper.get(`/ProjectInvitation/GenerateReport/${projectId}/${invitationId}`, { responseType: 'blob'});
    return axiosHelper.get(
      `/ProjectInvitation/GenerateReport/${projectId}/${invitationId}/${fileId}`,
      { responseType: "json" }
    );
  }
  public GetDocuments(
    projectId: string,
    invitationId: string
  ): Promise<IReportGeneration> {
    return axiosHelper.get(
      `/ProjectInvitation/GetDocuments/${projectId}/${invitationId}`,
      { responseType: "json" }
    );
  }
}
