import {
  PrimaryButton,
  Label,
  Stack,
  StackItem,
  IIconProps,
  registerIcons,
  TextField,
  ITextFieldStyles,
  IButtonStyles,
  IconButton,
  SelectionMode,
  IDropdown,
  mergeStyleSets,
  ScrollablePane,
  IScrollablePaneStyles,
  IStackStyles,
  Shimmer,
  ShimmerElementType,
  IShimmerElement,
  mergeStyles,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";

import { Navigate, useNavigate, useParams } from "react-router-dom";
import { QandAIcon } from "@fluentui/react-icons-mdl2";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";

import ProjectInvitationService from "../../common/api-services/ProjectInvitationService";

//import projects from "../../../local-json/Projects.json"
import styles from "./privacy.module.scss";
import { relative } from "path";
import {
  IProjectInvitation,
  TacResponseStatus,
} from "../../common/models/IProjectInvitation";
import { IFaqDocument } from "../../common/models/IFaqDocument";
import { IPrivacy } from "../../common/models/IPrivacy";

//import { InvalidatedProjectKind } from "typescript";

const Privacy = () => {
  let navigate = useNavigate();
  const gotoFaq = () => {
    let path = `/home`;
    navigate(path);
  };

  registerIcons({
    icons: {
      QandAIcon: <QandAIcon />,
    },
  });

  const wrapperClass = mergeStyles({
    padding: 2,
    selectors: {
      "& > .ms-Shimmer-container": {
        margin: "10px 0",
      },
    },
  });

  const contentStyles = mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      border: "1px solid",
      minHeight: "400px",
      minWidth: "600px",
      borderRadius: "25px",
    },
    pane: {
      maxWidth: 400,
      position: "relative",
    },
  });

  const shimmerWithElementRow = [
    { type: ShimmerElementType.circle },
    { type: ShimmerElementType.gap, width: "2%" },
    { type: ShimmerElementType.line },
  ];
  const textfieldStyles: Partial<ITextFieldStyles> = {
    root: {
      width: 350,
      paddingRight: 50,
      paddingBottom: 10,
    },
  };
  const stackStyles50: IStackStyles = {
    root: {
      width: `50%`,
    },
  };
  const tacContentStyles: IStackStyles = {
    root: {
      backgroundColor: `#eeeeee`,
      //outline: `1px solid red`,
      width: `100%`,
      height: `100%`,
      overflowY: `auto`,
      padding: `0px 15px`,
      maxHeight: `calc(100vh - 300px)`,
    },
  };

  const scrollablePaneStyles: Partial<IScrollablePaneStyles> = {
    root: contentStyles.pane,
  };

  //const { projectId = "", invitationId = ""} = useParams();
  const params = useParams<{ projectId: string; invitationId: string }>();

  const service = useMemo(() => new ProjectInvitationService(), []);

  const projectFaqIcon: IIconProps = {
    iconName: "QandAIcon",
    styles: {
      root: [
        {
          selectors: {
            ">span": {
              verticalAlign: "top",
            },
          },
        },
      ],
    },
  };

  const [
    isTacSubmitted,
    { setTrue: setTacSubmitted, setFalse: resetTacSubmitted },
  ] = useBoolean(false);

  const [privacy, setPrivacy] = useState<IPrivacy>({ title: "", content: "" });

  const onDownloadFaq = (
    event: any,
    from: string,
    blobUrl: any,
    fileName?: string
  ) => {
    service.GetFaq().then((data) => {
      if (
        data.faqDocumentFetchSuccess == null ||
        data.faqDocumentFetchSuccess == false
      ) {
        navigate(`/notfound`);
      } else {
        fetch(`data:application/pdf;base64,${data.faqDocumentData}`)
          .then(function (base64resp) {
            if (base64resp) {
              return base64resp.blob();
            }
          })
          .then(function (faqBlob) {
            if (faqBlob) {
              //console.log("Filename:" + data.reportFilename)
              const faqBlobUrl = URL.createObjectURL(faqBlob);
              let alink = document.createElement("a");
              alink.href = faqBlobUrl;
              alink.download = data.faqDocumentFilename ?? "FAQ.pdf";
              alink.style.display = "none";
              document.body.append(alink);
              alink.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                })
              );
            }
          });
      }
    });
  };
  useEffect(() => {
    service.GetPrivacyPolicy().then((data: IPrivacy) => {
      setPrivacy(data);
    });
  }, []);

  return (
    <Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Stack horizontal horizontalAlign="start">
          <Label className={styles.PageHeader}>{privacy?.title}</Label>
        </Stack>
        <Stack horizontal horizontalAlign="end">
          <PrimaryButton
            text="FAQ"
            className={styles.buttonNewProject}
            iconProps={projectFaqIcon}
            onClick={(event) => onDownloadFaq(event, `click`, ``, ``)}
          />
        </Stack>
      </Stack>
      <Stack>
        <hr className={styles.horizontalLine}></hr>
      </Stack>

      <Stack horizontal>
        <Stack styles={tacContentStyles}>
          <div
            id="tacContent"
            className="tacContent"
            style={{ padding: "0px 15px" }}
            dangerouslySetInnerHTML={{
              __html: privacy.content,
            }}
          ></div>
        </Stack>
      </Stack>
      <Stack>
        <hr className={styles.horizontalLine}></hr>
      </Stack>
    </Stack>
  );
};

export default Privacy;
