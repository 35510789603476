import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    "Content-type": "application/json"
  }
});


instance.interceptors.response.use((response) => {
  return response.data;
});

export default instance;