import { IProjectInvitation } from "../models/IProjectInvitation";
import { IFaqDocument } from "../../common/models/IFaqDocument";
import axiosHelper from "./AxiosApi";
import { IPrivacy } from "../models/IPrivacy";
export default class ProjectInvitationService {
  public GetInvitationDetails(
    projectId: string,
    invitationId: string
  ): Promise<IProjectInvitation> {
    return axiosHelper.get(
      `/ProjectInvitation/GetInvitationDetails/${projectId}/${invitationId}`
    );
  }

  public UpdateInvitation(
    input: IProjectInvitation
  ): Promise<IProjectInvitation> {
    return axiosHelper.post("/ProjectInvitation/SetProjectInvitation", input);
  }

  public GetFaq(): Promise<IFaqDocument> {
    return axiosHelper.get(`/ProjectInvitation/GetFaq`);
  }
  public GetLegalUrl(): Promise<string> {
    return axiosHelper.get(`/ProjectInvitation/GetLegalUrl`);
  }
  public GetPrivacyPolicy(): Promise<IPrivacy> {
    return axiosHelper.get(`/ProjectInvitation/GetPrivacyPolicy`);
  }
}
